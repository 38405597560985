<template>
  <div
    id="markdown"
    v-html="markdown"
  />
</template>

<script>
import { marked } from 'marked'

export default {
    props: {
        markedContent: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    computed: {
        markdown() {
            let parsedTextImg = this.markedContent.split('/uploads/').join(`${this.api_url}/uploads/medium_`);
            let parsedText = this.markedContent;

            if (parsedTextImg) {
                return marked(parsedTextImg);
            } else {
                return marked(parsedText);
            }
        },
    },
    updated() {
        let wrapper = document.getElementById("markdown");
        if (wrapper) {
            let links = wrapper.getElementsByTagName('a');
            let len = links.length;
            for (let i = 0; i < len; i++) {
                links[i].target = "_blank";
                links[i].rel = "noreferrer noopener";
            }
        }
    },
    mounted() {
        let wrapper = document.getElementById("markdown");
        if (wrapper) {
            let links = wrapper.getElementsByTagName('a');
            let len = links.length;
            for (let i = 0; i < len; i++) {
                links[i].target = "_blank";
                links[i].rel = "noreferrer noopener";
            }
        }
    }
}
</script>